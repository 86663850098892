import React from 'react';
import $ from 'jquery';
import { Table, Button, Icon, Grid, Image } from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { Link } from "react-router-dom";

import Load from '../../load';
import Total from './total';
import Item from './item';
import Customer from './choosecustomer';
import server from '../../functions/server';
import userId from '../../functions/userid';
import domain from '../../functions/domain';
import ViewBill from '../view/orders';
import BarCode from '../../utils/barcode';
import Person from "../../assets/img/faces/ayo-ogunseinde-1.jpg"

const styles = {
  appBar: { position: 'relative', },
  flex: { flex: 1, },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Add extends React.Component {

  state = {
    open: false, stocks: [], receivedAmount: 0, offlineOrder: [], out: [], removedStocks: [],
    redeemPoints: 0,
  };

  componentDidMount() {
    if (this.props.type) {
      this.getEditableStocks();
    }
    this.getAllProductFromLocal();
    this.getAllProducts();
    if (!this.props.type) {
      this.addNewProduct();
    }
    
  }

  getEditableStocks() {
    let stocks = this.props.stocks;
    let out = [];
    let id = 1;
    let masterId = 0;
    stocks.map((c) => {
      let price = Number(c.price);
      let tax = Number(c.tax);
      price = Number(price + ((price * tax) / 100)).toFixed(2);
      masterId = c.master_id;
      out.push({ 
        "item_id": c.id, 
        "beforestock": c.quantity, 
        "id": id, 
        "no_stock": c.no_stock, 
        "quantity": c.quantity, 
        "price": c.prize - (c.prize * c.discount_value / 100),
        "originalPrice": c.prize,
        "discount": c.discount_value, 
        "discountrs": this.discountrs(c.price, c.quantity, c.tax, c.prize),
        "tax": c.tax, "type": "existing",
        "product_name": c.product_name,
        "finalPrice": price,
        "order_id": c.item_id 
      })
      id = id + 1;
    })
    this.setState({ stocks: out, masterId })
  }

  getAllProducts() {
    let context = this;
    let url = server() + '/stocks/getallstocks.php';
    let data = {id: this.props.data.id, toDay: ''};
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      let stockOptions = [];
      try {
        out = JSON.parse(data);
        let items = JSON.stringify(out);
        localStorage.setItem('stocks', items);
        for (var i = 0; i < out.length; i++) {
          let stock = out[i];
          let feature = "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + stock.feature_image;
          stockOptions.push({
            key: stock.id, value: stock.id,
            text: stock.item_number + " " + stock.product_name + " " + stock.local_name,
            content: <div style={{display : 'flex', width: '100%'}}>
              <div style={{width: '10%'}}>
                <Image src={feature} style={{width: '100%'}}/>
              </div>
              <div style={{ marginLeft: '4%', width: '90%' }}>
                {stock.item_number + " " + stock.product_name + " " + stock.local_name}
              </div>
            </div>,
          });
        }
      } catch(e) {}
      context.setState({ out: out, stockOptions, load: false });
    });
  }

  getAllProductFromLocal() {
    let out = localStorage.getItem('stocks');
    if (!out) {
      return;
    }
    out = JSON.parse(out);
    let stockOptions = [];
    for (var i = 0; i < out.length; i++) {
      let stock = out[i];
      let feature = "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + stock.feature_image;
      stockOptions.push({
        key: stock.id, value: stock.id,
        text: stock.item_number + " " + stock.product_name + " " + stock.local_name,
        content: <div style={{display : 'flex', width: '100%'}}>
              <div style={{width: '10%'}}>
                <Image src={feature} style={{width: '20px', height: '20px'}}/>
              </div>
              <div style={{ marginLeft: '4%', width: '90%' }}>
                {stock.item_number + " " + stock.product_name + " " + stock.local_name}
              </div>
            </div>,
      });
    }
    if (this.props.type) {
      this.setState({ stockOptions });
      return;
    }
    this.setState({ out, stockOptions });
  }

  addNewProduct = (barcode) => {
    let stocks = this.state.stocks;
    let id = stocks.length + 1;
    stocks.push({id: id, "type": "new", quantity: ''});
    this.setState({stocks}, () => {
      if (barcode && barcode.length > 0) {
        let product = this.getProductByBarcode(barcode);
        if (!product) return;
        this.updateItem(id, 'item_id', product.id);
        return;
      }
    });
    return id;
  }

  checkInternetConnection() {
    var xhr = new ( window.ActiveXObject || XMLHttpRequest )( "Microsoft.XMLHTTP" );
    xhr.open( "HEAD", "//" + window.location.hostname + "/?rand=" + Math.floor((1 + Math.random()) * 0x10000), false );
    try {
      xhr.send();
      return ( xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304) );
    } catch (error) {
      return false;
    }
  }

  handleBarcode(barcode) {
    let { stocks } = this.state;
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      if (stock.barcode === barcode) {
        this.updateItem(stock.id, 'quantity', stock.quantity + 1);
        return;
      }
    }
    this.addNewProduct(barcode);
  }

  removeItem = (id) => {
    let stocks = this.state.stocks;
    let newStocks = []; let removedStocks = this.state.removedStocks;
    let count = 0;
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      if (stock.id !== id) {
        stock["id"] = ++count;
        newStocks.push(stock);
      }
      else {
        if (stock.type != 'new') {
          removedStocks.push(stock);
        }
      }
    }
    this.setState({stocks: newStocks, removedStocks});
  }

  getValueByKey = (id, key) => {
    for (var i = 0; i < this.state.out.length; i++) {
      let stock = this.state.out[i];
      if (stock.id === id) {
        return stock[key];
      }
    }
    return 0;
  }

  getProductByBarcode = (barcode) => {
    for (var i = 0; i < this.state.out.length; i++) {
      let stock = this.state.out[i];
      if (stock.barcode === barcode) {
        return stock;
      }
    }
    return null;
  }

  updateItem = (id, key, value) => {

    let stocks = this.state.stocks;
    let newStocks = [];
    for (var i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      
      if (stock.id === id) {
        stock[key] = value;
        stock.price = this.getValueByKey(stock.item_id, 'prize');
        stock.originalPrice = this.getValueByKey(stock.item_id, 'prize');
        
        if (key === 'item_id') {
          stock.quantity = stock.quantity ? stock.quantity : 1;
          stock.discount = this.getValueByKey(value, 'discount_percentage');
          stock.tax = this.getValueByKey(value, 'tax');
          stock.no_stock = this.getValueByKey(value, 'no_stock');
          stock.min_stock = this.getValueByKey(value, 'min_stock');
          stock.no_stock = this.getValueByKey(value, 'no_stock');
          stock.product_name = this.getValueByKey(value, 'product_name');
          stock.dnp = this.getValueByKey(value, 'dnp');
          stock.barcode = this.getValueByKey(value, 'barcode');
          stock.price = stock.price - (stock.price * stock.discount / 100);
          let discountrs = this.discountrs(stock.price, stock.quantity, stock.tax, stock.originalPrice);
          stock.discountrs =  discountrs;
          let price = this.convertToNumber(stock.price);
          let tax = this.convertToNumber(stock.tax);
          price = (price + ((price * tax) / 100));
          price = Number(price * stock.quantity).toFixed(2);
          stock.finalPrice = price;
        }
        
        if (stock.price && (stock.discount || stock.discountrs || stock.quantity)) {
          if (key == 'discount') {
            stock.price = stock.price - (stock.price * stock.discount / 100);
            let discountrs = this.discountrs(stock.price, stock.quantity, stock.tax, stock.originalPrice);
            stock.discountrs =  discountrs;
            let price = this.convertToNumber(stock.price);
            let tax = this.convertToNumber(stock.tax);
            price = (price + ((price * tax) / 100));
            price = Number(price * stock.quantity).toFixed(2);
            stock.finalPrice = price;
          }
          if (key == 'discountrs') {
            stock.price = this.calculateDiscountPercentage(stock.originalPrice, stock.tax, value, stock.quantity);
            stock.discount = this.calculateDiscountPer(stock.originalPrice, stock.tax, value, stock.quantity);
            let price = this.convertToNumber(stock.price);
            let tax = this.convertToNumber(stock.tax);
            price = (price + ((price * tax) / 100));
            price = (price * stock.quantity).toFixed(2);
            stock.finalPrice = price;
          }
          if (key == 'quantity') {
            stock.discountrs = '';
            stock.discount = '';
            let price = this.convertToNumber(stock.price);
            let tax = this.convertToNumber(stock.tax);
            price = (price + ((price * tax) / 100));
            price = Number(price * stock.quantity).toFixed(2);
            stock.finalPrice = price;
          }
        }
        if (key == 'totprice') {
          let originalPrice = stock.originalPrice;
          let tax = stock.tax;
          let quantity = stock.quantity;
          originalPrice = Number(originalPrice);
          tax = Number(tax);
          quantity = Number(quantity);
          originalPrice = originalPrice + ((originalPrice * tax)/100);
          originalPrice = originalPrice * quantity;
          stock.discountrs = Number(originalPrice - value).toFixed(2);
          stock.price = this.removeTax(value, tax, quantity);
          stock.discount = this.findDiscountPercentage(originalPrice, value);
          stock.finalPrice = value;
        }
      }
      newStocks.push(stock);
    }
    this.setState({stocks: newStocks});
  }

  findDiscountPercentage(originalPrice, discountoriginalPrice) {
    let discount = (originalPrice - discountoriginalPrice);
    discount = discount/originalPrice * 100;
    discount = discount.toFixed(2);
    return discount;
  }

  removeTax(finalprice, tax, quantity) {
    finalprice = finalprice / quantity;
    if (tax < 10) { tax = '1.0'+tax; }
    else { tax = '1.'+tax; }
    tax = Number(tax);
    finalprice = (finalprice/tax);
    return finalprice;
  }

  discountrs = (price, quantity, tax, originalPrice) => {
    originalPrice = originalPrice * quantity;
    originalPrice = Number(originalPrice + ((originalPrice * tax) / 100)).toFixed(2);
    price = price * quantity;
    price = Number(price + ((price * tax) / 100)).toFixed(2);
    price = originalPrice - price;
    return price;
  }

  calculateDiscountPer(originalPrice, tax, valueRs, quantity) {
    originalPrice = this.convertToNumber(originalPrice);
    tax = this.convertToNumber(tax);
    valueRs = this.convertToNumber(valueRs);
    quantity = this.convertToNumber(quantity);
    originalPrice = originalPrice * quantity;
    originalPrice = originalPrice + ((originalPrice * tax)/100);
    let discountoriginalPrice = originalPrice - valueRs;
    let discount = (originalPrice - discountoriginalPrice);
    discount = discount/originalPrice * 100;
    discount = discount.toFixed(2);
    return discount;
  }

  calculateDiscountPercentage = (originalPrice, tax, valueRs, quantity) => {
    originalPrice = this.convertToNumber(originalPrice);
    tax = this.convertToNumber(tax);
    valueRs = this.convertToNumber(valueRs);
    quantity = this.convertToNumber(quantity);
    originalPrice = originalPrice * quantity;
    originalPrice = originalPrice + ((originalPrice * tax)/100);
    originalPrice = originalPrice - valueRs;
    if (tax < 10) { tax = '1.0'+tax; }
    else { tax = '1.'+tax; }
    tax = Number(tax);
    originalPrice = (originalPrice/tax);
    originalPrice = originalPrice/quantity;
    return originalPrice;
  }

  convertToNumber(value) {
    try { value = parseFloat(value); }
    catch (e) { value = 0; }
    return value;
  }

  geteditableData() {
    let stockData = [];
    for (var i = 0; i < this.state.stocks.length; i++) {

      let stock = this.state.stocks[i];
      if (!stock.item_id || stock.item_id < 1) {
        alert("Invalid Stock Name"); return false;
      }
      if (isNaN(stock.quantity)) {
        alert("Invalid Quantity"); return false;
      }
      let price = (stock.quantity * stock.price);
      let total = price + price * stock.tax / 100;
      let no_stock = stock.no_stock;
      let min_stock = stock.min_stock;
      let product_name = stock.product_name;
      let dnp = stock.dnp;
      let discount = stock.discount;
      stockData.push({
        id: stock.item_id, quantity: stock.quantity, tax: stock.tax, price: stock.price,
        total, no_stock, min_stock, product_name, dnp, discount, type: stock.type, beforestock: stock.beforestock,
        order_id: stock.order_id
      });
    }
    return stockData;
  }

  getStockData(type) {
    let stockData = [];
    let customerName = this.state.customerName;
    let customerMobile = this.state.customerMobile;
    let customerID = this.state.customerID;
    let receivedAmount = this.state.receivedAmount;
    if (!customerID || customerID < 1) {
      alert("Please Choose Customer"); return;
    }
    for (var i = 0; i < this.state.stocks.length; i++) {

      let stock = this.state.stocks[i];
      if (!stock.item_id || stock.item_id < 1) {
        alert("Invalid Stock Name"); return false;
      }
      if (stock.quantity == 0 || !stock.quantity < 0) {
        alert("Invalid Quantity"); return false;
      }
      if (stock.price == 0 || stock.price < 0 || !stock.price) {
        alert("Invalid Value"); return false;
      }
      let price = (stock.quantity * stock.price);
      let total = price + price * stock.tax / 100;
      let no_stock = stock.no_stock;
      let min_stock = stock.min_stock;
      let product_name = stock.product_name;
      let dnp = stock.dnp;
      let discount = stock.discount;
      stockData.push({
        id: stock.item_id, quantity: stock.quantity, tax: stock.tax, price: stock.price,
        total, no_stock, min_stock, product_name, dnp, discount
      });
    }
    return stockData;
  }

  sendMessageinWhatsApp = (id, customerMobile) => {
    let message = "Thanks for visiting "+this.props.data.shop_name+".\n\n You can view and download your invoice anytime using the link\n\n https://bills.strackit.com/"+this.props.data.id+"/"+id;
    let url = "https://wa.me/91"+customerMobile+"?text="+message;
    window.open(url, '_blank');
  }

  sendSms = (id, customerMobile, type) => {
    let context = this;
    let url = server() + '/orders/sendbill.php';
    let data = { id: userId(), order_id: id, mobile: customerMobile,
      name: this.props.data.shop_name, shop_id: this.props.data.id
    };
    if (type) { data["type"] = "1"; }
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      try {
        data = JSON.parse(data);
        if (typeof data == 'object') {
          alert('Order Placed successfully!!!\n\n'+data.message);
          document.getElementById('linkButton').click();
          return;
        }
      } catch (e) {
        document.getElementById('linkButton').click();
      }
    });
  }

  updateAndAddOfflineStorage(data) {
    let out = localStorage.getItem('orders');
    let item = [];
    if (!out) {
      data.local_order_id = 1;
      item.push(data);
    }
    else {
      item = JSON.parse(out);
      data.local_order_id = out.length + 1;
      item.push(data);
    }
    item = JSON.stringify(item);
    localStorage.setItem('orders', item);
  }


  offlineStorage(data, stockData) {
    this.updateAndAddOfflineStorage(data);
    stockData.map((v) => {
      let dnp, item_number, hsn_code, no_stock, min_stock, description, specification, shop_id, feature_image,
          mastercategory, category, product_id, discount;

      this.state.stocks.map((s) => {
        if (v.id == s.id) {
          dnp = s.dnp;
          item_number = s.item_number;
          hsn_code = s.hsn_code;
          no_stock = s.no_stock;
          min_stock = s.min_stock;
          description = s.description;
          specification = s.specification;
          shop_id = s.shop_id;
          feature_image = s.feature_image;
          mastercategory = s.mastercategory;
          category = s.category;
          product_id = s.product_id;
          discount = s.discount;
        }
      })
      v.dnp = dnp;
      v.discount = discount;
      v.stock_id = v.id;
      v.total_price = v.total;
      v.status = "5";
      v.returned = "0";
      v.item_number = item_number;
      v.master_id = "0";
      v.local_name = v.product_name;
      v.hsn_code = hsn_code;
      v.prize = v.price;
      v.no_stock = no_stock;
      v.min_stock = min_stock;
      v.description = description;
      v.specification = specification;
      v.shop_id = shop_id;
      v.feature_image = feature_image;
      v.mastercategory = mastercategory;
      v.category = category;
      v.product_id = product_id;
    })
    this.setState({ stocks: [{ id: 1, quantity: 0, discount: '', discountrs: '' }], customerMobile: "", customerName: "", receivedAmount: "",
    customerID: "", customerAddress: "", customerGSTIN: "" })
    this.child.clearData();
    this.child1.openDialog(stockData, this.state.customerName);
  }

  async ordersMovesFromOfflineToOnline() {
    let context = this;
    let url = server()+'/orders/ordermovefromofflinetoonline.php';
    let orders = localStorage.getItem('orders');
    if (!orders) {
      return;
    }
    if (orders.length > 10) {
      let data = { 'orders': orders };
      $.ajax({
        url, data, type: 'POST',
        beforeSend: function(x) {
          context.setState({ load: true });
        }
      }).done((data) => {
        if (data == 1) {
          alert('Offline Data Stored Successfully');
          let item = [];
          item = JSON.stringify(item);
          localStorage.setItem('orders', item);
        }
        else {
          alert('Error');
        }
      });
    }
  }

  saveAll = (type, size) => () => {
    let context = this;
    let internetConnection = window.navigator.onLine;
    let ledger = this.state.ledger;
    let stockData = this.getStockData(type);
    if (!stockData || !stockData.length) {
      alert('Invalid Stock!!!')
      return;
    }
    if (!this.state.ledger) {
      if (!this.state.receivedAmount) { ledger = 0; }
      else { alert('Select Ledger!!!'); return; }
    }
    let url = server() + '/orders/createorder.php';
     if (type == 5) {
     url = server() + "/quotation/generatequotation.php";
    }
    let data = {
      shop_id: this.props.data.id, customer_id: this.state.customerID, user_order: 0,
      data: stockData, customer_mobile: this.state.customerMobile,
      customer_name: this.state.customerName, amount: this.state.receivedAmount,
      promotion: this.state.promotion,
      ledger: ledger, customer_address: this.state.customerAddress, customer_gstin: this.state.customerGSTIN,
    };
    if (this.state.redeemPoints > 0) {
      data["redeem_points"] = this.state.customerPoints;
    }
    if (!internetConnection) {
      this.offlineStorage(data, stockData);
      return;
    }
    this.ordersMovesFromOfflineToOnline();
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      if (data > 0) {
        if (type === 0) {
          document.getElementById('linkButton').click();
          let url = server()+"/orders/viewbill/?s=" + context.props.data.id + "&o=" + data;
          if (size === 'A5') {
            url += '&size=A5';
          }
          window.open(url, '_blank');
        } else if (type == 1) {
          context.sendSms(data, context.state.customerMobile);
        }
        else if (type == 10) {
          context.sendMessageinWhatsApp(data, context.state.customerMobile);
        }
        else if (type == 2) {
          document.getElementById('linkButton').click();
          let url = server()+"/orders/viewbill/?s=" + context.props.data.id + "&o=" + data + "&t=1";
          window.open(url, '_blank');
        }
        else {
          document.getElementById('linkButton').click();
          context.sendSms(data, this.state.customerMobile, 1);
        }
        context.setState({
          stocks: [{ id: 1, quantity: 0, discount: '', discountrs: '' }], customerMobile: "", customerName: "", receivedAmount: "",
          customerID: "", customerAddress: "", customerGSTIN: "", load: false
        })
      } else {
        context.setState({ load: false })
        alert("Failed");
      }
    });
    this.child.clearData();
  }

  updateBill() {
    let context = this;
    let { removedStocks, masterId } = this.state;
    let geteditableData = this.geteditableData();
    let url = server() + '/orders/editorder.php';
    let data = { item: geteditableData, removedStocks: removedStocks, masterId };
    $.ajax({
      url: url, data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      context.setState({ load: false })
      if (data == 1) {
        context.props.dialogClose();
      }
      else {
        alert('Error')
      }
    });
  }

  render() {
    let total = this.state.stocks.length;
    let tableData = this.state.stocks.map((stock) => {
      let context = this;
      return (<Item data={context.props.data} item={stock} removeItem={context.removeItem}
                updateItem={context.updateItem} addNewProduct={context.addNewProduct} total={total}
                stockOptions={context.state.stockOptions} allStocks={context.state.out}/>);
    });
    return (
      <span>
        { this.props.type ? null :
          <AppBar style={styles.appBar}>
            <Toolbar>
              <Link to="/billing">
                <IconButton  id='linkButton' style={{color: 'white !important'}} aria-label="Close">
                  <CloseIcon style={{color: 'white'}} />
                </IconButton>
              </Link>
              <Typography variant="h6" color="inherit" style={styles.flex}>Billing</Typography>
              <Button positive onClick={this.handleClose} style={{display: 'none'}}>Save Products</Button>
            </Toolbar>
          </AppBar>
        }
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell style={{width: '20%'}}>Product</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Disc(%)</Table.HeaderCell>
              <Table.HeaderCell>Disc(Rs)</Table.HeaderCell>
              <Table.HeaderCell>Tax</Table.HeaderCell>
              <Table.HeaderCell>Total Price</Table.HeaderCell>
              <Table.HeaderCell>Taxable Price</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
           </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData}
          </Table.Body>
        </Table>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            { !this.props.type ?
              <Customer name={this.state.customerName} mobile={this.state.customerMobile}
                address={this.state.customerAddress} gstin={this.state.customerGSTIN}
                data={this.props.data} amount={this.state.receivedAmount}
                redeemPoints={this.state.redeemPoints}
                ref={instance => { this.child = instance; }}
                updateItem={(key, value) => this.setState({[key]: value})} />
                :
                null
            }
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Total stocks={this.state.stocks}/>
          </Grid.Column>
        </Grid>
        { !this.props.type ?
          <Table>
            <Table.Row>
              <Table.Cell>
                <Button fluid negative style={{marginRight: 40}} onClick={this.saveAll(0, 'A4')}>
                  Generate Bill (A4)
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button fluid negative style={{marginRight: 40}} onClick={this.saveAll(0, 'A5')}>
                  Generate Bill (A5)
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button fluid positive style={{marginRight: 40}} onClick={this.saveAll(1)}>
                  Generate and Send Bill as SMS
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button fluid positive style={{marginRight: 40}} onClick={this.saveAll(10)}>
                  Generate and Send Bill on WhatsApp
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{display: 'none'}}>
              <Table.Cell>
                <Button fluid negative style={{marginRight: 40}} onClick={this.saveAll(2)}>
                  Generate and View Bill (Local Name)
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button fluid positive style={{marginRight: 40}} onClick={this.saveAll(3)}>
                  Generate and Send Bill as SMS (Local Name)
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table>: 
          <center>
            <Button fluid positive style={{marginRight: 40, width: '150px' }} onClick={this.updateBill.bind(this)}>
              Save
            </Button>
          </center>
        }
        <Load open={this.state.load}/>
        { this.state.offlineOrder ?
          (<ViewBill from={'offline'} stocks={this.state.out} ref={instance => { this.child1 = instance; }}
            order={this.state.offlineOrder}  />):''
        }
        <BarCode onScan={this.handleBarcode.bind(this)}/>
      </span>
    );
  }

}
