import React from "react";
import { Table, Button } from "semantic-ui-react";
import server from "../../functions/server";
import $ from "jquery";
import Buttons from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Load from "../../loads";
import { AddToHomeScreenOutlined } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class View extends React.Component {
  state = {
    out: [],
  };

  getReport() {
    let context = this;
    let url = server() + "/customers/getallcustomers.php";
    let data = {
      id: this.props.id,
      customerName: this.props.customerName,
      toDay: this.props.toDay,
    };
    let type = this.getType1();
    if (type == "Logistics Customer") {
      url = server() + "/customers/getallcustomersonly.php";
      data = {
        id: this.props.id,
        customerName: this.props.customerName,
        toDay: this.props.toDay,
      };
    }
    if (type == "Logistics Vendor") {
      url = server() + "/customers/getallvendor.php";
      data = {
        id: this.props.id,
        customerName: this.props.customerName,
        toDay: this.props.toDay,
      };
    }
    $.ajax({
      url: url,
      data,
      type: "POST",
      beforeSend: function (x) {
        context.setState({
          load: true,
        });
      },
    }).done((data) => {
      let out;
      try {
        out = JSON.parse(data);
      } catch (e) {
        alert("Failed");
      }
      context.setState({ out: out, load: false, open: true });
    });
  }

  getType1() {
    if (window.location.pathname == "/customers") {
      return "Customer";
    }
    if (window.location.pathname == "/logistics-customers") {
      return "Logistics Customer";
    }
    if (window.location.pathname == "/logistics-vendor") {
      return "Logistics Vendor";
    }
  }

  export() {
    let downloadLink;
    let filename = "GstReport";
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.getElementById("export");
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
    filename = filename ? filename + ".xls" : "excel_data.xls";
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = "data:" + dataType + ", " + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
    }
  }

  render() {
    return (
      <div>
        <Button primary onClick={this.getReport.bind(this)}>
          Export
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false });
          }}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ open: false });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              GST REPORT
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Load open={this.state.load} />
          <div style={{ paddingLeft: "1%" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="export"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Export"
            />
            <Table celled striped id="export">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Cust./Purchaser Name</Table.HeaderCell>
                  <Table.HeaderCell>Cust./Purchaser Mobile</Table.HeaderCell>
                  <Table.HeaderCell>Cust./Purchaser Address</Table.HeaderCell>
                  <Table.HeaderCell>GSTIN</Table.HeaderCell>
                  <Table.HeaderCell>Balance</Table.HeaderCell>
                  <Table.HeaderCell>DueDate</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.out.map((r) => {
                  return (
                    <Table.Row>
                      <Table.HeaderCell>{r.id}</Table.HeaderCell>
                      <Table.HeaderCell>{r.name}</Table.HeaderCell>
                      <Table.HeaderCell>{r.mobile}</Table.HeaderCell>
                      <Table.HeaderCell>{r.address}</Table.HeaderCell>
                      <Table.HeaderCell>{r.gstin}</Table.HeaderCell>
                      <Table.HeaderCell>
                        {Number(r.balance).toFixed(2)}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {r.duedate.split("-")[2] +
                          "-" +
                          r.duedate.split("-")[1] +
                          "-" +
                          r.duedate.split("-")[0]}
                      </Table.HeaderCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Dialog>
      </div>
    );
  }
}
